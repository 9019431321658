import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Dropdown, Row, Col, Container, Button, Card, DropdownButton } from 'react-bootstrap'
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import 'swiper/swiper-bundle.css';
import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';
import * as MovieAction from '../../../actions/MovieAction'
import * as HomeAction from '../../../actions/HomeActions'

import { CircularProgress, Grid } from '@mui/material';
import { ROUTES } from '../../../constant/routes';
import { IMAGE_ENDPOINT } from '../../../constant/ApiList';
import empty from '../../../assets/regal_images/empty-box (1).png'
import rent_icon from '../../../assets/regal_images/Rent (1).png'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { Base64 } from 'js-base64';

//img

// install Swiper modules
SwiperCore.use([Navigation]);

const MovieList = () => {
    const dispatch = useDispatch()
    const { getAllMovies } = bindActionCreators(MovieAction, dispatch)
    const [movies, setMovies] = useState([])
    const [allMovies, setAllMovies] = useState([])
    const location = useLocation()
    const [genre, setGenre] = useState([])
    const [language, setLanguage] = useState([])
    const history = useHistory()
    const { getAllHomeData } = bindActionCreators(HomeAction, dispatch)
    const [accessFilter, setAccessFilter] = useState('ALL')
    const [home, setHome] = useState([])
    const profile_id = JSON.parse(sessionStorage.getItem('profile'))?.id
    const user_id = JSON.parse(sessionStorage.getItem('user'))?.id

    console.log("location", location)
    useEffect(() => {
        (async () => {
            let userObj = {
                category_name: location?.state?.data?.category_name || "MOVIES"
            }
            const resData = await getAllMovies(userObj)
            setMovies(resData?.data)
            setAllMovies(resData?.data)
            let user = {
                user_id: user_id ? user_id : "null",
                profile_id: profile_id ? profile_id : "null"
            }
            const Homedata = await getAllHomeData(user)
            setHome({ ...Homedata, data: JSON.parse(Base64.decode(Homedata.data)) })

            // setLanguage(Homedata?.data?.Language)
            // setGenre(Homedata?.data?.Genre)

        })();
    }, [location?.state])

    const handleLanguage = (data) => {
        history.push(ROUTES.moviesByLanguage, { id: data?.id })

    }
    const handleGenre = (data) => {
        history.push(ROUTES.moviesByGenre, { id: data?.id })

    }

    const handleAccessFilter = (MovieType) => {
        if (MovieType !== "ALL") {
            let localArr = []
            setMovies([])
            allMovies?.filter((data) => {

                if (data?.movie_access == MovieType) {
                    localArr.push(data)
                }
            })
            setMovies(localArr)
        }
        else {
            setMovies(allMovies)
        }

    }
    return (
        <>
            <section className="m-profile setting-wrapper">
                <Container>
                    <div className='movielist-dropdwn' style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <h4 className="main-title mb-5 mt-3">{location?.state?.data?.category_name || "MOVIES"}</h4>

                        <div className='dropdown-btn-genre drpdwn-colour' style={{ display: "flex" }}>
                            <DropdownButton id="dropdown-item-button" title={'Filter by Genre'} className='mr-2 '>
                                <div style={{ maxHeight: '200px', overflowY: 'auto' }} className='remove-scroll'>
                                    {
                                        home?.data?.Genre?.data.map((data, index) => (
                                            <Dropdown.Item style={{ cursor: "pointer", color: "white" }} onClick={() => handleGenre(data)} key={index} as="button">
                                                {data?.genre_title}
                                            </Dropdown.Item>
                                        ))
                                    }

                                </div>
                            </DropdownButton>
                            <DropdownButton id="dropdown-item-button" title={'Filter by Language'} className='mr-2'>
                                <div style={{ maxHeight: '200px', overflowY: 'auto' }} className='remove-scroll'>
                                    {
                                        home?.data?.Language?.data?.map((data, index) => (
                                            <Dropdown.Item style={{ cursor: "pointer", color: "white" }} onClick={() => handleLanguage(data)} key={index} as="button">
                                                {data?.language_name}
                                            </Dropdown.Item>
                                        ))
                                    }

                                </div>
                            </DropdownButton>
                            <DropdownButton id="dropdown-item-button" title={'Filter by Access'}>
                                <div style={{ maxHeight: '200px', overflowY: 'auto' }} className='remove-scroll'>
                                    <Dropdown.Item style={{ cursor: "pointer", color: "white" }} as="option" value={'SVOD'} onClick={() => handleAccessFilter("SVOD")}>
                                        SVOD
                                    </Dropdown.Item>
                                    <Dropdown.Item style={{ cursor: "pointer", color: "white" }} as="option" value={'TVOD'} onClick={() => handleAccessFilter("TVOD")}>
                                        TVOD
                                    </Dropdown.Item>
                                    <Dropdown.Item style={{ cursor: "pointer", color: "white" }} as="option" value={'FREE'} onClick={() => handleAccessFilter("FREE")}>
                                        FREE
                                    </Dropdown.Item>
                                    <Dropdown.Item style={{ cursor: "pointer", color: "white" }} as="option" value={'ALL'} onClick={() => handleAccessFilter("ALL")}>
                                        ALL MOVIES
                                    </Dropdown.Item>
                                </div>
                            </DropdownButton>
                        </div>
                    </div>

                    <Grid container spacing={2}>
                        {
                            movies?.length ?
                                movies?.map((data, index) => (
                                    <Grid item lg={2} xl={2} md={2} sm={4} xs={6} key={index} className='grid-img-cls trending-info align-items-center w-100 animated fadeInUp iq-ltr-direction'>
                                        <Link to={`${ROUTES.moviedetails}/${data?.slug}`}>
                                            <img src={`${IMAGE_ENDPOINT + data?.movie_thumbnail}`} className="thumb-img-filter" alt={data?.movie_name} />
                                            {/* {data?.movie_access == "TVOD" && <div className="block-icon-tvod-list">
                                                <img src={rent_icon} width={"75px"} />


                                            </div>} */}
                                            <Card className='hover-class'>
                                                <Card.Img variant="top" src={`${IMAGE_ENDPOINT}/${data?.movie_poster}`} />
                                                <Card.Body style={{ marginTop: "-1rem" }}>
                                                    <span style={{ color: "#ffffff", fontSize: "13px" }}>{data?.movie_name}
                                                    </span><br />
                                                    <div style={{ marginTop: "-0.3rem" }}>
                                                        <span style={{ color: "#ffffff", fontSize: "10px" }}>Duration: {data?.movie_duration}
                                                        </span>
                                                        <p style={{ fontSize: "10px" }} dangerouslySetInnerHTML={{ __html: data?.movie_description?.length <= 60 ? data?.movie_description : data?.movie_description?.substring(0, 60) + "..." }}></p>
                                                        <div style={{ display: "flex", background: "#ffffff", color: "#000000", fontWeight: "bolder", borderRadius: "0.5rem", }}>
                                                            <div style={{ fontSize: "13px", margin: "0 auto", padding: "5px" }}><i className="fa fa-info" aria-hidden="true"></i>  Details</div>
                                                        </div>
                                                    </div>

                                                </Card.Body>
                                            </Card>
                                        </Link>
                                    </Grid>
                                ))
                                :
                                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <img src={empty} alt='empty' style={{ height: "60px" }} />
                                        <p className='pt-4'> Sorry, No result found.</p>
                                        {/* <CircularProgress /> */}
                                    </div>
                                </Grid>
                        }

                    </Grid>
                </Container>
            </section>
        </>
    )
}
export default MovieList;