import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Container, Col, Row, Nav, Tab, } from 'react-bootstrap'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { gsap } from 'gsap'
import logo from '../../../assets/regal_images/regal_logo.png'
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import * as HomeActions from "../../../actions/HomeActions";
import { IMAGE_ENDPOINT } from '../../../constant/ApiList';
import { ROUTES } from '../../../constant/routes';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Share from '../common/Share';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import * as MovieAction from '../../../actions/MovieAction'
import * as HomeAction from '../../../actions/HomeActions'
import AlertMessage from '../common/AlertMessage';
import { toast } from 'react-toastify';
import plus from '../../../assets/regal_images/plus.png'
import check from '../../../assets/regal_images/check.png'
import SwiperCore, { Autoplay, Pagination, Navigation, EffectCreative, Thumbs } from "swiper";
import { Box, Tooltip, styled } from '@mui/material';
import VideoJS from '../../../player/player'
import videojs from 'video.js';
import animatedGIF from '../../../assets/regal_images/Animation.gif'
import { EffectCoverflow } from 'swiper';
import axios from 'axios';
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

import Modal from '@mui/material/Modal';
import { Base64 } from 'js-base64';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: "70vh",
    width: 400,
    bgcolor: 'rgb(25,25,25)',
    border: '2px solid rgb(255,255,255,0.5)',
    boxShadow: 24,
    borderRadius: "10px",
    p: 4,
};
const styleforAlert = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: "70vh",
    width: 600,
    bgcolor: 'rgb(25,25,25)',
    border: '2px solid rgb(255,255,255,0.5)',
    boxShadow: 24,
    borderRadius: "10px",
    p: 4,
}

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const gsapAnimate = {
    getData: (elem) => {
        const option = {
            opacity: 0,
            scale: 1,
            position: {
                x: 0,
                y: 0,
            },
            ease: "",
            duration: 1,
            delay: .4,
            rotate: 0
        }
        if (elem !== undefined) {
            option.position.x = gsapAnimate.validValue(elem.dataset.iqPositionX, 0)
            option.position.y = gsapAnimate.validValue(elem.dataset.iqPositionY, 0)
            option.rotate = gsapAnimate.validValue(elem.dataset.iqRotate, 0)
            option.scale = gsapAnimate.validValue(elem.dataset.iqScale, 1)
            option.opacity = gsapAnimate.validValue(elem.dataset.iqOpacity, 0)
            option.delay = gsapAnimate.validValue(elem.dataset.iqDelay, .4)
            option.duration = gsapAnimate.validValue(elem.dataset.iqDuration, 1.5)
            option.ease = gsapAnimate.validValue(elem.dataset.iqEase, '')
            const setOption = { opacity: option.opacity, scale: option.scale, x: option.position.x, y: option.position.y, ease: option.ease, rotate: option.rotate, duration: option.duration, delay: option.delay }
            return setOption
        } else {
            return { opacity: 0 }
        }
    },
    onStart: (elem) => {
        const setOption = gsapAnimate.getData(elem)
        gsap.from(elem, setOption)
    },
    onEnd: (elem) => {
        const setOption = gsapAnimate.getData(elem)
        gsap.to(elem, setOption)
    },

    onStartEnd: (elem) => {
        const setOption = gsapAnimate.getData(elem)
        const setEndOption = gsapAnimate.getData(elem)
        setEndOption.opacity = 1
        setEndOption.x = 0
        setEndOption.y = 0
        setEndOption.rotate = 0
        setEndOption.scale = 1
        gsap.fromTo(elem, setOption, setEndOption)
    },
    validValue: (attr, defaultVal) => {
        if (attr !== undefined && attr !== null) {
            return Number(attr)
        }
        return Number(defaultVal)
    }
}

function Slider() {
    const playerRef = React.useRef(null);

    const dispatch = useDispatch()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [slug, setSlug] = useState("")
    const [watchListopen, setWatchlistOpen] = React.useState(false);
    const isLoggedIn = JSON.parse(sessionStorage.getItem('isLoggedIn'))
    const profile_id = JSON.parse(sessionStorage.getItem('profile'))?.id
    const [save, setSave] = useState(false)
    const { addIntoWatchList } = bindActionCreators(MovieAction, dispatch)
    const { getMovieDetails } = bindActionCreators(HomeAction, dispatch)
    const [thumbsSwiper, setThumbsSwiper] = React.useState(null);
    const [desktop, setDesktop] = useState(false)

    const { setCompleteHours } = MovieAction

    const { getSliderData } = bindActionCreators(HomeAction, dispatch)
    const [popupOpen, setPopupOpen] = useState(false)
    const [selectedMovie, setSelectedMovie] = useState()
    const { removeFromWatchlist } = MovieAction
    const history = useHistory()
    const [sliderData, setSliderData] = useState([])
    const [count, setCount] = useState(-2);
    SwiperCore.use([Autoplay, EffectCoverflow, Thumbs])
    const [animationStart, setAnimationStart] = useState(false)
    const [hoursLeft, setHoursLeft] = useState(calculateHoursLeft());
    const [hoursAlert, setHoursAlert] = useState(false)
    const [completedHour, setCompletedHour] = useState(false)
    const user_id = JSON.parse(sessionStorage.getItem('user'))?.id
    const [tempData, setTempData] = useState("")
    const isRunningInElectron = navigator.userAgent.toLowerCase().indexOf(' electron/') > -1;

    let maxMovieWatchFlag = false
    useEffect(() => {
        (async () => {
            let userObj = {
                profile_id: profile_id ? profile_id : "null"
            }
            const sliderRes = await getSliderData(userObj)
            if (sliderRes?.statuscode == 200) {

                setSliderData({ ...sliderRes, data: JSON.parse(Base64.decode(sliderRes.data)) })


            }
            const intervalId = setInterval(() => {
                setHoursLeft(calculateHoursLeft());
            }, 1000);
        })();
    }, [save])
    function calculateHoursLeft() {
        const now = new Date();
        const nextDay = new Date(now);
        nextDay.setDate(now.getDate() + 1);
        nextDay.setHours(0, 0, 0, 0);

        const timeDifference = nextDay - now;
        const hoursLeft = Math.floor(timeDifference / (1000 * 60 * 60));

        return hoursLeft;
    }

    const handleClick = (event, slug) => {
        setAnchorEl(event.currentTarget);
        setSlug(slug)
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const handleClose = () => {
        setAnchorEl(null);
    };

    const animationInit = () => {
        if (document.querySelector('.swiper-container .swiper-slide-active') !== null) {

            const gsapElem = document.querySelector('.swiper-container .swiper-slide-active').querySelectorAll('[data-iq-gsap="onStart"]')

            Array.from(gsapElem, (elem) => {
                return gsapAnimate.onStartEnd(elem)
            })
        }
    }

    const AddWatchList = async (id) => {
        if (isLoggedIn && profile_id) {
            let userObj = {
                profile_ID: profile_id,
                movie: id
            }
            const res = await addIntoWatchList(userObj)
            if (res?.statuscode == 200) {
                setAnimationStart(true)
                setTimeout(() => {
                    setAnimationStart(false)
                }, 2000);
                toast.success(res?.message)
                setSave(!save)

            }
            else {
                toast.error("Something went wrong.")
            }
        }
        else {
            toast.error("Please login to continue.")
        }
    };

    const RemoveWatchList = async (id) => {
        if (isLoggedIn && profile_id) {
            let userObj = {
                profile_id: profile_id,
                movie_id: id,
                series_id: ""
            }
            const res = await removeFromWatchlist(userObj)
            if (res.statuscode == 200) {
                toast.success(res?.message)
                setSave(!save)
            }
        }
        else {
            toast.error("Please login to continue.")
        }
    }

    const handleRedirect = (slug) => {
        history.push(`${ROUTES.moviedetails}/${slug}`)
    }
    const handlePopupClose = () => {
        setPopupOpen(false);
    };
    const handlePlayNow = async (data) => {
        const res = await axios.get("https://api.ipify.org/?format=json");
        let userObj = {
            user_id: user_id,
            profile_id: profile_id,
            ip_address: res.data.ip

        }
        const resData = await getMovieDetails(data?.movie?.slug, userObj)

        if (!isLoggedIn) {
            setPopupOpen(true)

        }
        else {
            if (data?.max_movie_watch_complete == "true" && data?.movie?.movie_access == "SVOD") {
                setPopupOpen(true)
                setSelectedMovie(data)

            }
            else {
                if (data?.movie?.movie_access == "TVOD" && data?.get_status == "false") {
                    setHoursAlert(true)
                    setTempData(data)
                }
                else if (data?.movie?.movie_access == "TVOD" && data?.get_status == "completed") {
                    setCompletedHour(true)
                }
                else {
                    if (data?.movie?.movie_is_for18Plus == "Yes") {
                        if (!popupOpen) {
                            setSelectedMovie(data)
                            setPopupOpen(true)
                        }
                        else {
                            (data?.movie?.isDesktop == "true" && !isRunningInElectron) ?
                                (
                                    () => {
                                        setPopupOpen(false);
                                        setDesktop(true);
                                    }
                                )()
                                :
                                history.push(ROUTES.video, { movie: resData, resume: data?.last_watching_time })
                        }
                    }
                    else {
                        (data?.movie?.isDesktop == "true" && !isRunningInElectron) ?
                            setDesktop(true)
                            :
                            history.push(ROUTES.video, { movie: resData, resume: data?.last_watching_time })
                    }
                }

            }
        }

        // else {
        //     if (data?.movie?.movie_is_for18Plus == "Yes") {
        //         if (!popupOpen) {
        //             setSelectedMovie(data)
        //             setPopupOpen(true)
        //         }
        //         else {
        //             history.push(ROUTES.video, { movie: { ...data, Data: data?.movie } })
        //         }

        //     }
        //     else {
        //         history.push(ROUTES.video, { movie: { Data: data?.movie } })
        //     }
        // }
    }

    const handleInstallClick = () => {
        // setDesktop(false)
        // const myZipFile = require('../../../premiumflix-win32-x64.zip');
        // const link = document.createElement('a');
        // link.href = myZipFile;
        // link.download = 'premiumflix-win32-x64.zip';
        // document.body.appendChild(link);
        // link.click();
        // document.body.removeChild(link);
    };

    const handlePlayerReady = (player) => {
        playerRef.current = player;
        player.on('waiting', () => {
            videojs.log('player is waiting');
        });

        player.on('dispose', () => {
            videojs.log('player will dispose');
        });
    };

    const handleHoursDialogueClose = () => {
        setHoursAlert(false);
        setCompletedHour(false)
    };

    const handleHoursContinue = async () => {
        const send_status = await setCompleteHours({
            user: user_id,
            movie: tempData?.movie?.id
        })
        if (send_status?.statuscode == 200) {
            (tempData?.movie?.isDesktop == "true" && !isRunningInElectron) ? (
                () => {
                    setHoursAlert(false);
                    setSave(!save)
                    setDesktop(true);
                }
            )() :
                history.push(ROUTES.video, { movie: { Data: tempData?.movie } })

        }

    }

    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#ffffff',
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 220,
            fontSize: theme.typography.pxToRem(12),
            border: '1px solid #dadde9',
        },
    }));



    return (
        <>
            <Modal
                open={popupOpen}
                onClose={handlePopupClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                {!isLoggedIn ? <Box sx={style}>

                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <img src={logo} width={"100px"} />
                    </div>
                    <hr />

                    <div className="login-popup" >
                        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mt: 2, textAlign: "center" }}>
                            Please login to continue.
                        </Typography>
                        <button onClick={() => history.push("/login")} className={"btn btn-hover iq-button mt-3"} style={{ display: "block", margin: "0 auto" }}>
                            Login
                        </button>
                    </div>
                </Box> :
                    (selectedMovie?.max_movie_watch_complete == "true" ?
                        <Box sx={style}>

                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <img src={logo} width={"100px"} />
                            </div>
                            <hr />

                            <div className="login-popup" >
                                <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mt: 2, textAlign: "center" }}>
                                    You have already seen this film maximum number of times. To watch it again, purchase a Diamond Plan.
                                </Typography>
                                <div className='mt-3' style={{ display: "flex", justifyContent: "center" }}>

                                    <button onClick={handlePopupClose} className={"btn btn-hover iq-button ml-2"} >
                                        Okay
                                    </button>
                                </div>
                            </div>
                        </Box> : <Box sx={style}>

                            <div style={{ display: "flex", justifyContent: "left", fontSize: "19px", color: "red", fontWeight: "900" }}>
                                {/* <img src={logo} width={"100px"} /> */}
                                Maturity Rating: 18+
                            </div>
                            <hr />

                            <div className="login-popup" >
                                <Typography id="modal-modal-title" sx={{ mt: 2, textAlign: "center" }}>
                                    May contain mature content, nudity, violence, foul language, substances.
                                </Typography>
                                <Typography id="modal-modal-title" sx={{ mt: 2, textAlign: "left" }}>
                                    I CONFIRM THAT I AM 18 YEARS AND ABOVE
                                </Typography>
                                <div className='mt-3' style={{ display: "flex", justifyContent: "end" }}>
                                    <button onClick={() => handlePlayNow(selectedMovie)} className={"btn btn-hover iq-button"}>
                                        OK
                                    </button>
                                    <button onClick={handlePopupClose} className={"btn btn-hover iq-button ml-2"} >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </Box>)
                }
            </Modal>
            <Modal
                open={hoursAlert}
                onClose={(handleHoursDialogueClose)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <Box sx={styleforAlert}>

                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <img src={logo} width={"100px"} />
                    </div>
                    <hr />

                    <div className="login-popup" >
                        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mt: 2, textAlign: "center" }}>
                            Once you start watching this movie, you have 72 hours to finish it; if not, you must rent it again.
                        </Typography>
                        <div style={{ display: "flex", justifyContent: "end", marginTop: "1.5rem" }}>

                            <button onClick={handleHoursContinue} className={"btn btn-hover iq-button"}>
                                Watch Now
                            </button>
                            <button onClick={handleHoursDialogueClose} className={"btn btn-hover ml-3 iq-button"}>
                                Close
                            </button>
                        </div>
                    </div>
                </Box>
            </Modal>
            <Modal
                open={completedHour}
                onClose={(handleHoursDialogueClose)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <Box sx={styleforAlert}>

                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <img src={logo} width={"100px"} />
                    </div>
                    <hr />

                    <div className="login-popup" >
                        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mt: 2, textAlign: "center" }}>
                            You have exceeded 72 hours of movie watching limitation , please rent now to continue                         </Typography>
                        <div style={{ display: "flex", justifyContent: "end", marginTop: "1.5rem" }}>
                            <button onClick={(handleHoursDialogueClose)} className={"btn btn-hover ml-3 iq-button"}>
                                Close
                            </button>
                        </div>
                    </div>
                </Box>
            </Modal>
            <section id="home" className="iq-main-slider p-0 iq-rtl-direction">
                {/* <div id="prev5" className="swiper-button swiper-button-prev"><i className="fa fa-chevron-left"></i></div>
                <div id="next5" className="swiper-button swiper-button-next"><i className="fa fa-chevron-right"></i></div> */}
                <Swiper
                    effect={'coverflow'}
                    grabCursor={true}
                    centeredSlides={true}
                    loop={true}
                    slidesPerView={1.17}
                    coverflowEffect={{
                        rotate: 0,
                        stretch: 150,
                        depth: 200,
                        modifier: 1.8,
                    }}

                    initialSlide={1}
                    pagination={{ el: '.swiper-pagination', clickable: true }}
                    navigation={{
                        nextEl: 'swiper-buton-next',
                        prevEl: 'swiper-button-pre',
                        clickable: true
                    }}
                    modules={[EffectCoverflow, Pagination, Navigation]}
                    className='swiper-container '






                    // navigation={{
                    //     prevEl: '#prev5',
                    //     nextEl: '#next5'
                    // }}

                    // pagination={{
                    //     "clickable": true
                    // }}
                    // modules={[Autoplay, Pagination, Navigation]}
                    autoplay={{
                        delay: 3500,
                    }}
                    // speed={700}
                    // effect='coverflow'
                    // onInit={() => { animationInit() }}
                    // thumbs={{ swiper: thumbsSwiper }}
                    // onSlideChangeTransitionStart={() => animationInit()}
                    onSlideChange={(swiper) => {

                        if (sliderData[count]?.slider_type == "Video") {

                            swiper.autoplay.stop();
                            playerRef.current.src(sliderData[count]?.video_url)
                            playerRef.current.play()
                            playerRef.current.on('ended', () => {
                                swiper.autoplay.start();
                            });

                        }
                        if (count == sliderData?.length - 1)
                            setCount(-1);
                        else {
                            setCount((prev) => prev + 1);
                        }
                    }}
                    style={{ marginTop: "70px", marginBottom: "1rem" }}
                // loop={true}
                // id="home-slider"
                // className="slick-bg-1 m-0 p-0 slider-bg"
                >
                    {
                        sliderData?.data?.map((data, index) => (

                            <SwiperSlide
                                // className="slide slick-bg"
                                key={index}
                                style={{ backgroundSize: "cover" }}
                                onClick={() => handleRedirect(data?.movie?.slug)}
                            >
                                <img className='home-slider-res' src={IMAGE_ENDPOINT + (data?.web_banner || data?.movie?.movie_poster)} alt="" />

                            </SwiperSlide>
                        ))
                    }


                </Swiper>
                {/* <div className='custom-slider-thumb' style={{ position: "absolute", width: "50%", top: "75vh", right: "10px" }}>
                    <Swiper
                        onSwiper={setThumbsSwiper}
                        modules={[Autoplay, Pagination, Navigation]}
                        autoplay={{
                            delay: 2000,
                        }}
                        loop={true}
                        spaceBetween={10}
                        slidesPerView={3}
                        // freeMode={true}
                        watchSlidesVisibility={true}
                        watchSlidesProgress={true}
                    >
                        {sliderData?.data?.map((data, index) => (
                            <SwiperSlide key={index}>
                                <img className='thumb-slider' style={{ borderRadius: "10px" }} src={`${IMAGE_ENDPOINT + (data?.web_banner || data?.movie?.movie_poster)}`} alt={`Thumbnail ${index}`} />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div> */}
            </section>
            <Modal
                open={desktop}
                onClose={() => setDesktop(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <Box sx={styleforAlert}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <img src={logo} alt='regal_logo' style={{ width: "100px" }} />
                    </div>
                    {/* <div style={{ position: "absolute", top: "5px", right: "5px" }}>
                        <div onClick={() => setDownload(false)}>
                            <HighlightOffIcon style={{ height: "40px", cursor: "pointer" }} />
                        </div>
                    </div> */}
                    <hr />
                    <Typography id="modal-modal-description" sx={{ mt: 2, fontWeight: "700" }}>
                        To View this Movie, Please install Desktop Application.
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        1. To install Desktop App, click the download button.
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        2. Once download has been completed, Extract the Zip with the option Extract to Premiumflix
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        3. Run premiumflix.exe after extracting the file from the zip file.
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        4. Pin premiumflix.exe to your taskbar and enjoy streaming.
                    </Typography>
                    <div className='pt-3' style={{ display: "flex", justifyContent: "end" }}>
                        <button onClick={handleInstallClick} className="btn btn-hover mb-1 iq-button" style={{ marginBottom: "10px" }}>
                            Download
                        </button>
                        {/* <button onClick={()=>setDesktop(false)} className="btn btn-hover iq-button ml-2">
                            Back
                        </button> */}
                    </div>
                </Box>
            </Modal>
        </>
    )
}
export default Slider