import React, { useEffect, useState } from 'react'
import { Container, Col, Row, Nav, Tab, Card, } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';

import { IMAGE_ENDPOINT } from '../../../constant/ApiList';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../../../constant/routes';
import { Grid } from '@mui/material';
import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';
import * as MovieAction from '../../../actions/MovieAction'

import { toast } from 'react-toastify';
import * as HomeAction from '../../../actions/HomeActions';
import { Base64 } from 'js-base64';

export default function WebSeries(props) {
    const history = useHistory()
    const isLoggedIn = JSON.parse(sessionStorage.getItem('isLoggedIn'))
    const profile_id = JSON.parse(sessionStorage.getItem('profile'))?.id
    const user_id = JSON.parse(sessionStorage.getItem('user'))?.id
    const dispatch = useDispatch()
    const { addIntoWatchList } = bindActionCreators(MovieAction, dispatch)
    const { getAllHomeData } = bindActionCreators(HomeAction, dispatch)
    const [home, setHome] = useState([])

    const { removeFromWatchlist } = MovieAction
    const [updatedArry, setUpdatedArray] = useState([])
    const [save, setSave] = useState(false)
    const handleSeriesRedirect = (id) => {
        history.push(ROUTES.seriesDetails, { series: id })
    }

    useEffect(() => {
        (async () => {
            let userObj = {
                user_id: user_id ? user_id : "null",
                profile_id: profile_id ? profile_id : "null"
            }
            const resData = await getAllHomeData(userObj)
            // setUpdatedArray(resData?.data?.Series)
            setUpdatedArray({ ...resData, data: JSON.parse(Base64.decode(resData.data)) })


        })();
    }, [save])
    const addSeriesIntoWatchList = async (id) => {
        if (isLoggedIn && profile_id) {
            let userObj = {
                profile_ID: profile_id,
                series: id
            }
            const res = await addIntoWatchList(userObj)
            if (res?.statuscode == 200) {
                toast.success(res?.message)
                setSave(!save)
            }
            else {
                toast.error("Something went wrong.")
            }
        }
        else {
            toast.error("Please login to continue.")
        }
    }
    const RemoveWatchList = async (id) => {
        if (isLoggedIn && profile_id) {
            let userObj = {
                profile_id: profile_id,
                movie_id: "",
                series_id: id
            }
            const res = await removeFromWatchlist(userObj)
            if (res.statuscode == 200) {
                toast.success(res?.message)
                setSave(!save)
            }
        }
        else {
            toast.error("Please login to continue.")
        }
    }


    return (
        <div className='main-content'>
            <section id="iq-favorites">
                <Container fluid>
                    <Row>
                        <Col sm="12" className="overflow-hidden">
                            <div className="d-flex align-items-center justify-content-between">
                                <h6 style={{ textTransform: "uppercase" }} className="main-title">{props?.webSeries?.Name}</h6>
                                {/* <Link className="iq-view-all" to="/movie-category">View All</Link> */}
                            </div>
                            <div id="">
                                {/* <div id="prev4" className="swiper-button swiper-button-prev"><i className="fa fa-chevron-left"></i></div>
                                <div id="next4" className="swiper-button swiper-button-next"><i className="fa fa-chevron-right"></i></div> */}
                                <Swiper
                                    navigation={{
                                        prevEl: '#prev4',
                                        nextEl: '#next4'
                                    }}
                                    breakpoints={{
                                        320: { slidesPerView: 1 },
                                        550: { slidesPerView: 2 },
                                        991: { slidesPerView: 3 },
                                        1400: { slidesPerView: 4 }
                                    }}
                                    loop={false}
                                    slidesPerView={4}
                                    spaceBetween={20}
                                    as="ul"
                                    className="favorites-slider list-inline  row p-0 m-0 iq-rtl-direction mt-3">
                                    {

                                        updatedArry?.data?.Series?.data?.map((data, index) => (
                                            <SwiperSlide as="li" key={index}>
                                                <div className="position-relative series-div">
                                                    <Link to={`${ROUTES.seriesDetails}/${data?.slug}`}>
                                                        <img src={`${IMAGE_ENDPOINT + data?.series_poster}`} className="series-img-slider" alt="" style={{ cursor: "pointer" }} />

                                                    </Link>
                                                    <div className='hover-class-series' style={{width:"100%"}}>
                                                        {/* <div className='pt-2' style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                                <h5>{data?.series_name}</h5>
                                                                <div style={{ fontSize: "25px" }}>
                                                                    <i className="fa fa-plus-circle mr-3" aria-hidden="true"></i>
                                                                    <i className="fa fa-share-alt-square" aria-hidden="true"></i>
                                                                </div>
                                                            </div> */}
                                                        <div>
                                                            <Grid container spacing={2} className='pb-2'>
                                                                <Grid item xs={4}>
                                                                    <Link to={`${ROUTES.seriesDetails}/${data?.slug}`}>
                                                                        <img src={`${IMAGE_ENDPOINT + data?.series_thumbnail}`} className="hover-thumbnail" style={{ cursor: "pointer" }} />
                                                                    </Link>

                                                                </Grid>
                                                                <Grid item xs={8}>
                                                                    <Link to={`${ROUTES.seriesDetails}/${data?.slug}`}>
                                                                        <p style={{ fontWeight: "bolder" }}>{data?.series_name}</p>
                                                                        <p style={{ marginTop: "0" }} dangerouslySetInnerHTML={{ __html: data?.series_description?.length <= 50 ? data?.series_description : data?.series_description?.substring(0, 50) + "..." }}></p>
                                                                    </Link>
                                                                    <div style={{ display: "flex", justifyContent: "end", fontSize: "30px", alignItems: "center" }}>
                                                                        {
                                                                            data?.iswatchlist ?
                                                                                <div style={{ cursor: "pointer" }} onClick={() => RemoveWatchList(data?.id)}>
                                                                                    <i className="fa fa-check-square mr-2" aria-hidden="true"></i>
                                                                                </div>
                                                                                :
                                                                                <div onClick={() => addSeriesIntoWatchList(data?.id)} style={{ cursor: "pointer" }}>
                                                                                    <i className="fa fa-plus-square mr-2" aria-hidden="true"></i>
                                                                                </div>


                                                                        }
                                                                        {/* <i className="fa fa-share-alt-square" aria-hidden="true"></i> */}

                                                                    </div>
                                                                </Grid>
                                                            </Grid>
                                                        </div>
                                                        <Link to={`${ROUTES.seriesDetails}/${data?.slug}`} style={{ display: "flex", background: "#ffffff", color: "#000000", fontWeight: "bolder", borderRadius: "0.5rem", }} className=''>
                                                            <div style={{ fontSize: "16px", margin: "0 auto", padding: "10px" }}><i className="fa fa-info" aria-hidden="true"></i>  Details</div>
                                                        </Link>
                                                    </div>
                                                    <div className="block-description pb-1">
                                                        {/* <h6 className="iq-title"><Link to="/show-details">{data?.series_name}</Link></h6> */}
                                                        <div className="movie-time d-flex align-items-center my-2 iq-ltr-direction">

                                                        </div>
                                                        {/* <div className="hover-buttons">
                                                            <Link to="/show-details" role="button" className="btn btn-hover iq-button">
                                                                <i className="fa fa-play mr-1" aria-hidden="true"></i>
                                                                Play Now
                                                            </Link>
                                                        </div> */}
                                                    </div>

                                                </div>
                                            </SwiperSlide>
                                        ))

                                    }

                                </Swiper>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div >
    )
}
